import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LocalStorageService from '../../services/LocalStorage';
import {routeUtils} from '../../utils/routesUtils'

function PublicRoute(props) {
    if (LocalStorageService.getAccessToken()) {
        let app_route = routeUtils(localStorage.getItem("app"));
        return <Redirect to={app_route} />;
    }
    return <Route { ...props } />
}

export default PublicRoute;
